:root {
  --sql-doc-background-conic-gradient: white;
  --sql-doc-text-format-bg-color: #f5f5f5;
  --sql-doc-text-format-code-block-color: #ff4081;
  --sql-doc-text-format: #ff8e4e;
  --sql-doc-text-format-code-block-constants-color: #007bff;
  --sql-doc-side-bar-color: #dddddd;
  --sql-doc-side-bar-border-color: #333333;
  --sql-doc-side-bar-bg-color: transparent;
  --sql-doc-side-bar-webkit-scrollbar-thumb-color: #333333;
  --sql-doc-side-bar-webkit-scrollbar-track-color: transparent;
  --sql-doc-content-color: black;
  --sql-doc-content-selection-bg-color: black;
  --sql-doc-content-selection-color: white;
  --sql-doc-textarea-bg-color: #f5f5f5;
  --sql-doc-textarea-color: black;
  --sql-doc-textarea-border-color: #333333;
  --sql-doc-how-to-query-btn-bg-color: #007bff;
  --sql-table-content-color: #333333;
  --sql-table-content-bg-color: #f5f5f5;
  --sql-table-content-border-color: #cccccc;
  --sql-table-content-row-border-color: #cccccc;
  --sql-back-btn-bg-color: #007bff;
  --sql-table-compare-bg-color: #eeeeee;
  --sql-table-compare-border-color: #dddddd;
  --sql-table-compare-row-border-color: #dddddd;
  --sql-doc-compare-model-bg-color: rgba(255, 255, 255, 0.5);
  --sql-doc-compare-model-content-bg-color: black;
  --sql-doc-compare-model-content-color: white;
  --sql-doc-compare-model-content-selection-bg-color: white;
  --sql-doc-compare-model-content-selection-color: black;
  --sql-add-folder-color: black;
  --sql-add-folder-bg-color: transparent;
  --sql-add-folder-hover-color: white;
  --sql-add-folder-hover-bg-color: #ccc;
  --sql-add-folder-selected-btn-bg-color: black;
  --sql-add-folder-selected-btn-color: white;
  --sql-doc-bottom-button-next-prev-bg-color: #f5f5f5;
  --sql-doc-bottom-button-next-prev-color: black;
  --sql-add-folder-selected-btn-bg-color-hovered: rgba(220,220,220,0.8);
  --sql-doc-heading-curved-underline-bg-gradient: linear-gradient(
    to right,
    #ccc,
    #888
  );
  --sql-li-color: black;

  /* Media queries for light theme */
  @media screen and (max-width: 767px) and (orientation: portrait) {
    --sql-doc-mobile-select-btn-bg-color: transparent;
    --sql-doc-mobile-select-btn-color: black;
    --sql-doc-side-bar-enable-bg-color: #f5f5f5;
    --sql-doc-mobile-prev-btn-bg-color: transparent;
    --sql-doc-mobile-prev-btn-color: black;
    --sql-doc-mobile-next-btn-bg-color: transparent;
    --sql-doc-mobile-next-btn-color: black;
  }
}

.sql-doc-toggle-theme {
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  right: 0px;
  height: fit-content;
  margin-right: 15px;
  margin-bottom: 5px;
}

.sql-doc-diagrams {
  position: relative;
  left: 1%;
  max-width: 100%;
}

.sql-doc-mobile-prev-next-div-bottom-btns{
  display: none;
}


.sql-doc-bottom-button-previous {
  width: 10%;
  font-size: 1.4vh;
  justify-content: space-around;
  text-align: center;
  border-radius: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.7%;
  background-color: var(--sql-doc-bottom-button-next-prev-bg-color);
  color: var(--sql-doc-bottom-button-next-prev-color);
  border: none;
  cursor: pointer;
  transition: background-color, width 0.3s;
}

.sql-doc-hr {
  margin-top: 2vh;
  margin-bottom: 0px;
  color: #ccc;
}

.sql-doc-bottom-button-next {
  width: 8%;
  font-size: 1.4vh;
  justify-content: space-around;
  text-align: center;
  border-radius: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.7%;
  background-color: var(--sql-doc-bottom-button-next-prev-bg-color);
  color: var(--sql-doc-bottom-button-next-prev-color);
  border: none;
  cursor: pointer;
  transition: background-color, width 0.3s;
}

.sql-doc-bottom-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 2vh;
  width: 60vw;
  justify-content: space-between;
  align-items: flex-start;
}

.sql-doc-top-buttons {
  margin-top: 1.3vh;
  display: flex;
  width: 60vw;
  justify-content: space-between;
}

.sql-doc-switch-icon {
  border: none;
  background: none;
}

.sql-doc-main-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: var(--sql-doc-background-conic-gradient);
  z-index: -1;
}

.sql-doc-main-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  overflow: auto;
}

p {
  margin: 0px;
  padding: 0px;
}

.sql-doc-text-format {
  color: var(--sql-doc-text-format);
  background-color: var(--sql-doc-text-format-bg-color);
  padding: 3px;
  border-radius: 5px;
  width: fit-content;
  margin: 2px;
  position: relative;
}

.sql-doc-text-format-variable {
  font-style: italic;
}

.sql-doc-text-format-code-block {
  color: var(--sql-doc-text-format-code-block-color);
  padding-left: 2px;
  border-radius: 5px;
  width: fit-content;
  position: relative;
  font-weight: bold;
}

.sql-doc-text-format-code-block-variable {
  font-style: italic;
}

.sql-doc-text-format-code-block-constants {
  color: var(--sql-doc-text-format-code-block-constants-color);
}

.sql-doc-diagrams {
  position: relative;
  left: 18%;
  max-width: 100%;
}

.btn-check-karo {
  height: 50px;
}

.sql-doc-mobile-select {
  display: none;
}

.sql-doc-side-bar {
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 22vw;
  background-color: var(--sql-doc-side-bar-bg-color);
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 18px;
  margin-top: 120px;
  font-size: 20px;
  color: var(--sql-doc-side-bar-color);
  border-right: 0.5px solid var(--sql-doc-side-bar-border-color);
  overflow-y: scroll;
  text-align: left;
  max-height: calc(100% - 120px);
}
.sql-doc-side-bar::-webkit-scrollbar,
.sql-doc-side-bar::-webkit-scrollbar-thumb,
.sql-doc-side-bar::-webkit-scrollbar-track {
  width: 5px;
  background-color: var(--sql-doc-side-bar-webkit-scrollbar-thumb-color);
}

.sql-doc-side-bar::-webkit-scrollbar-thumb {
  background-color: var(--sql-doc-side-bar-webkit-scrollbar-thumb-color);
}

.sql-doc-side-bar::-webkit-scrollbar-track {
  background-color: var(--sql-doc-side-bar-webkit-scrollbar-track-color);
}

/* For Firefox */
.sql-doc-side-bar {
  scrollbar-width: thin; /* or auto for default Firefox scrollbar */
  scrollbar-color: var(--sql-doc-side-bar-webkit-scrollbar-thumb-color) var(--sql-doc-side-bar-webkit-scrollbar-track-color);
}


.sql-doc-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 55vw;
  min-height: 90vh;
  color: var(--sql-doc-content-color);
  margin-left: 10%;
  padding-top: 8vh;
  gap: 1vh;
}

.sql-doc-content *::selection {
  background-color: var(--sql-doc-content-selection-bg-color);
  color: var(--sql-doc-content-selection-color);
}

.sql-doc-p {
  text-align: justify;
  font-size: 1.4vh;
  line-height: 3vh;
}

.sql-doc-heading {
  font-weight: 400;
  font-size: 3.5vh;
  align-items: flex-start;
  gap: 0px !important;
}

.sql-doc-textarea {
  background-color: var(--sql-doc-textarea-bg-color);
  width: 60vw;
  resize: none;
  overflow: hidden;
  margin: 0;
  padding: 15px;
  color: var(--sql-doc-textarea-color);
  box-sizing: border-box;
  text-align: left;
  font-size: 1.5vh;
  line-height: 1.5;
  min-height: 1.5em;
  max-height: 100vh;
  border: 1px solid var(--sql-doc-textarea-border-color);
  border-top-width: 5px;
  font-family: "Courier New", monospace;
  font-weight: normal;
  padding-left: 20px;
}

.sql-doc-how-btn {
  font-size: 1.5vh;
  margin-top: 2%;
  margin-left: auto;
  justify-content: center;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 1%;
  background-color: var(--sql-doc-how-to-query-btn-bg-color);
  border: none;
  cursor: pointer;
}

.sql-back-btn {
  font-size: 1.4vh;
  justify-content: center;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 1%;
  background-color: var(--sql-back-btn-bg-color);
  border: none;
  cursor: pointer;
  margin-bottom: 2vh;
}

.sql-table-compare {
  background-color: var(--sql-table-compare-bg-color);
  border: 1px solid var(--sql-table-compare-border-color);
  margin-top: 2vh;
}

.sql-table-compare-row {
  padding: 0.6vw;
  border: 1px solid var(--sql-table-compare-row-border-color);
  width: 50%;
}

.sql-table-content {
  background-color: var(--sql-table-content-bg-color);
  border: 0.7px solid var(--sql-table-content-border-color);
  align-items: center;
  color: var(--sql-table-content-color);
  text-align: justify;
  margin-top: 0vh;
  font-size: 1.4vh;
  line-height: 3vh;
  overflow-x: scroll;
}

.sql-table-content-row {
  padding: 0.6vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  min-width: 10vw;
  width: fit-content;
  border: 0.4px solid var(--sql-table-content-row-border-color);
}

.sql-table-container {
  max-width: 100vw;
  overflow: auto;
}

.sql-doc-pre-bullets {
  white-space: pre-wrap;
  font-family: inherit;
  font-size: inherit;
  line-height: 2.5em;
  margin: 0;
  padding: 0;
}

.sql-doc-compare-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--sql-doc-compare-model-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(3px);
}

.sql-doc-compare-model-content {
  position: relative;
  background-color: var(--sql-doc-compare-model-content-bg-color);
  padding: 20px;
  border-radius: 0px;
  color: var(--sql-doc-compare-model-content-color);
  max-width: 40%;
  font-size: 1.4vh;
}

.sql-doc-compare-model-content *::selection {
  background-color: var(--sql-doc-compare-model-content-selection-bg-color);
  color: var(--sql-doc-compare-model-content-selection-color);
}

.faXMark {
  font-size: 1.6vh;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.sql-add-folder {
  border: none;
  background-color: var(--sql-add-folder-bg-color);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-radius: 0;
  padding-left: 10px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: var(--sql-add-folder-color);
  font-size: 15px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

.sql-add-folder-span {
  text-overflow: ellipsis !important;
}

.sql-add-folder:hover {
  // background-color: var(--sql-add-folder-hover-bg-color);
  background-color: var(--sql-add-folder-selected-btn-bg-color-hovered);
  color: var(--sql-add-folder-hover-color);
}

.sql-selected-btn {
  background-color: var(--sql-add-folder-selected-btn-bg-color);
  color: var(--sql-add-folder-selected-btn-color);
}

.sql-doc-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.sql-doc-heading-curved-underline {
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
}

.sql-doc-heading-curved-underline::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 50%;
  background-image: var(--sql-doc-heading-curved-underline-bg-gradient);
  transform: scaleX(1);
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

.sql-doc-heading-curved-underline.hovered::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 50%;
  background-image: var(--sql-doc-heading-curved-underline-bg-gradient);
  transform: scale(0, 0);
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

.sql-doc-heading-curved-underline:hover::after {
  transform: scaleX(1);
}

.sql-doc-text-area-margin {
  margin-left: 20px;
}

.sql-li {
  list-style-type: none;
  padding-left: 20px;
  position: relative;
  margin-bottom: 8px;
  line-height: 1.5;
  text-indent: -16px;
}

.sql-li::before {
  content: "□";
  font-size: 16px;
  color: var(--sql-li-color);
  margin-right: 8px;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .sql-doc-main-container {
    padding: 10px;
  }

  .sql-doc-p {
    line-height: 1.5vh;
  }

  .sql-doc-diagrams {
    position: relative;
    left: 1%;
    max-width: 100%;
    fill: green;
  }

  .sql-doc-side-bar {
    display: none;
    position: absolute;
    z-index: -1;
  }

  .sql-doc-side-bar-enable {
    position: absolute;
    z-index: 500;
    background-color: var(--sql-doc-side-bar-enable-bg-color);
    opacity: 1;
    padding-left: 2px;
    width: 55vw;
    text-align: left;
    display: block;
  }

  .sql-doc-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100vw;
    min-height: 90vh;
    line-height: 2rem;
    color: var(--sql-doc-content-color);
    margin-left: 0%;
    padding-top: 8vh;
    font-size: 1.6vh;
    justify-items: flex-start;
  }

  .sql-doc-how-btn {
    font-size: 1.6vh;
    margin-top: 2%;
    margin-left: auto;
    justify-content: center;
    border-radius: 0;
    display: flex;
    align-items: center;
    padding: 3%;
    background-color: var(--sql-doc-how-to-query-btn-bg-color);
    border: none;
    cursor: pointer;
  }

  .sql-doc-textarea {
    background-color: var(--sql-doc-textarea-bg-color);
    width: 100%;
    resize: none;
    outline: none;
    margin: 0;
    padding: 15px;
    box-sizing: border-box;
    text-align: left;
    font-size: 1.5vh;
    color: var(--sql-doc-textarea-color);
    border: 1px solid var(--sql-doc-textarea-border-color);
  }

  .sql-doc-mobile-select {
    display: block;
    flex-direction: row;
    width: 100%;
    z-index: 1;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  .sql-doc-mobile-select-btn {
    border: none;
    background-color: var(--sql-doc-mobile-select-btn-bg-color);
    color: var(--sql-doc-mobile-select-btn-color);
  }

  .sql-doc-mobile-prev-btn {
    margin-left: auto;
    border: none;
    background-color: var(--sql-doc-mobile-prev-btn-bg-color);
    color: var(--sql-doc-mobile-prev-btn-color);
  }

  .sql-doc-mobile-next-btn {
    border: none;
    background-color: var(--sql-doc-mobile-next-btn-bg-color);
    color: var(--sql-doc-mobile-next-btn-color);
  }

  .sql-doc-compare-model-content {
    position: relative;
    background-color: var(--sql-doc-compare-model-content-bg-color);
    padding: 20px;
    border-radius: 0px;
    color: var(--sql-doc-compare-model-content-color);
    max-width: 90%;
    font-size: 1.4vh;
  }

  .sql-table-content-row {
    padding: 0.6vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    min-width: 20vw;
    width: fit-content;
    border: 0.4px solid var(--sql-table-content-row-border-color);
  }

  .sql-table-container {
    max-width: 90vw;
    overflow: auto;
  }
  .sql-doc-bottom-buttons {
    display: none;
  }
  .sql-doc-top-buttons {
    display: none;
  }

  .sql-doc-hr {
    margin-top: 20vh;
    margin-bottom: 0px;
    display: none;
  }

  .sql-doc-bottom-button-previous-mobile {
    width: 22vw;
    font-size: 1.5vh;
    text-align: center;
    border-radius: 0;
    padding: 0.7%;
    background-color: var(--sql-doc-bottom-button-next-prev-bg-color);
    color: var(--sql-doc-bottom-button-next-prev-color);
    border: none;
    cursor: pointer;
    transition: background-color, width 0.3s;
  }

  .sql-doc-bottom-button-next-mobile {
    width: 16vw;
    font-size: 1.4vh;
    text-align: center;
    border-radius: 0;
    padding: 0.7%;
    background-color: var(--sql-doc-bottom-button-next-prev-bg-color);
    color: var(--sql-doc-bottom-button-next-prev-color);
    border: none;
    cursor: pointer;
    transition: background-color, width 0.3s; 
  }
  .convert-query-left-container-convert-btn-icon-mobile-left-arrow{
    padding-right: 1.5vw;
  }
  .convert-query-left-container-convert-btn-icon-mobile-right-arrow{
    padding-left: 1.5vw;
  }

  .sql-doc-mobile-prev-next-div{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 1rem;
  }

  .sql-doc-mobile-prev-next-div-bottom-btns{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 0.5rem;
    margin-bottom: 3rem;
  }
}


@media screen and (max-width: 1024px) and (orientation: landscape) {
  .sql-doc-side-bar {
    position: absolute;
    z-index: 500;
    background-color: var(--sql-doc-side-bar-enable-bg-color);
    opacity: 1;
    padding-left: 2px;
    width: 26vw;
    text-align: left;
    display: block;
  }

  .sql-table-content {
    background-color: var(--sql-table-content-bg-color);
    border: 0.7px solid var(--sql-table-content-border-color);
    align-items: center;
    color: var(--sql-table-content-color);
    text-align: justify;
    margin-top: 0vh;
    font-size: 1.4vw;
    line-height: 3vh;
    overflow-x: scroll;
  }

  .sql-doc-p {
    font-size: 1.4vw;
    line-height: 3vw;
  }
  
  .sql-doc-heading {
    font-size: 3.5vw;
  }

  .sql-doc-textarea {
    font-size: 1.5vw;
  }

  .sql-doc-bottom-button-previous{
    font-size: 1.5vw;
    width: 10vw;
  }

  .sql-doc-bottom-button-next{
    font-size: 1.5vw;
    width: 8vw;
  }

  .sql-doc-mobile-prev-next-div-bottom-btns{
    display: none;
  }
}