.convert-query-main-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: conic-gradient(
    from 180deg at 50% 0%,
    rgba(255, 255, 255, 0) 0deg,
    #000000 358deg
  );
  transform: scaleX(-1);
  z-index: -1;
}

.convert-query-main-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.convert-query-left-container {
  color: white;
}

.convert-query-left-container *::selection {
  background-color: white;
  color: black;
}

.convert-query-right-container *::selection {
  background-color: black;
  color: white;
}

.convert-query-child-container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 7vh;
}

.convert-query-child-container-div {
  width: 73.72%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.convert-query-child-container-div-left-header {
  margin-top: 10.11%;
  margin-bottom: 2.32%;
  font-weight: 400;
  font-size: 5.5vh;
  line-height: 6.89vh;
  color: #ffffff;
}

.convert-query-child-container-div-right-header {
  margin-top: 10.11%;
  margin-bottom: 2.32%;
  font-weight: 400;
  font-size: 5.5vh;
  line-height: 6.89vh;
}

.convert-query-child-container-text-box {
  border-radius: 2px;
  width: 100%;
  height: 63.74%;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.convert-query-child-container-left-text-box {
  border: 1px solid #eeeeee;
  position: relative;
  padding-right: 0;
  padding-top: 1%;
}

.convert-query-child-container-right-text-box {
  border: 1px solid #000000;
  position: relative;
  padding-right: 0;
  padding-top: 1%;
}

.convert-query-child-container-text-area {
  width: 92%;
  height: 98%;
  background: transparent;
  border: 0;
  outline: none;
  resize: none;
  padding-top: 8%;
  margin-bottom: 8%;
  font-size: 1.8vh;
  margin-right: -5.6vw;
  padding-right: 5.6vw;
  position: absolute;
  z-index: 1;
  left: 7%;
}
.convert-query-child-container-right-text-box-animate-border {
  animation: borderChange 3.5s infinite;
}
/* @keyframes borderChange {
  0% {
    border-left-color: white;
    border-left-width: 3px;
  }
  25% {
    border-top-color: white;
    border-top-width: 3px;
  }
  50% {
    border-right-color: white;
    border-right-width: 3px;
  }
  75% {
    border-bottom-color: white;
    border-bottom-width: 3px;
  }
  90% {
    border-color: white;
    border-width: 5px;
    border-bottom-color: white;
  }
} */



.convert-query-left-container-convert-btn {
  margin-top: 3.04%;
  width: 24%;
  font-size: 1.7vh;
  margin-left: auto;
  justify-content: space-around;
  text-align: center;
  border-radius: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1%;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  transition:
    background-color,
    width 0.3s;
}

@media (hover: hover) {
  /* .convert-query-left-container-convert-btn:hover {
    background-color: #e0e0e0;
    width: 30%;
  }
  .convert-query-left-container-convert-btn:hover .convert-query-left-container-convert-btn-text {
    opacity: 0;
  }
  .convert-query-left-container-convert-btn:hover .convert-query-left-container-convert-btn-icon {
    transform: translateX(-250%);
    transition:
      transform,
      font-size 0.3s;
    font-size: 3vh;
  } */
}


.convert-query-left-container-convert-btn-text {
  margin-right: 0px;
  transition: opacity 0.3s;
}

.convert-query-left-container-convert-btn-icon {
  transition: transform 0.3s;
}
.convert-query-left-container-convert-btn:active {
  transform: translateY(10%);
}

.convert-query-child-container-icon-btns {
  border: 0;
  font-size: 2.2vh;
  color: white;
}

.convert-query-child-right-container-icon-btns {
  border: 0;
  font-size: 2.2vh;
}

.convert-query-child-container-icon {
  transition: font-size 0.3s;
  opacity: 0.6;
}
.convert-query-child-container-icon:hover {
  font-size: 125%;
  opacity: 1;
}
.convert-query-child-container-dropdown {
  height: 0%;
  position: absolute;
  top: 2%;
  z-index: 2;
}
.convert-query-child-container-corner-btn {
  position: absolute;
  bottom: 0%;
  z-index: 2;
}

.convert-query-child-container-format-icon {
  position: absolute;
  bottom: 2%;
  left: 2%;
  z-index: 2;
  transform: scaleX(-1);
}

.convert-query-child-container-clear-icon {
  position: absolute;
  bottom: 2%;
  right: 2%;
  z-index: 2;
  padding-right: 0.6vw;
  padding-bottom: 1vh;
}

#convert-query-input-query-dropdown {
  background-color: transparent;
  border: 0.5px groove #cccccc;
  width: 14vh;
  padding: 0.4vw 0.6vw;
  margin-right: 0.5vw;
  font-size: 1.6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}

#convert-query-input-query-dropdown::after {
  display: none !important;
}

#convert-query-output-query-dropdown {
  background-color: transparent;
  border: 0.5px groove black;
  color: #000;
  width: 14vh;
  padding: 0.4vw 0.6vw;
  margin-right: 0.5vw;
  font-size: 1.6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}

#convert-query-output-query-dropdown::after {
  display: none !important;
}

.convert-query-child-container-output-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding-top: 1%;
}

#convert-query-icons-tooltip {
  padding: 2px 6px;
  border-radius: 0px;
  font-size: 75%;
}

.convert-query-child-container-dropdown-items {
  font-size: 1.5vh;
}

.convert-query-child-container-text-area::-webkit-scrollbar {
  width: 0.5em;
  display: block;
  margin-left: auto;
}

.convert-query-child-container-text-area::-webkit-scrollbar-track {
  background: transparent;
}

.convert-query-child-container-text-area::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

.convert-query-child-container-left-text-area {
  color: white;
}
.convert-query-child-container-left-text-area::-webkit-scrollbar-thumb {
  background-color: white;
}

.convert-query-child-container-right-text-area::-webkit-scrollbar-thumb {
  background-color: black;
}

.convert-query-child-container-right-text-area::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 8vh;
}

.convert-query-child-container-right-text-area::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 8vh;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .convert-query-main-container::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
      from 90deg at 0% 50%,
      #000000 0deg,
      rgba(255, 255, 255, 0) 360deg
    );
    transform: scaleY(-1);
    z-index: -1;
  }
  .convert-query-main-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .convert-query-child-container {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .convert-query-child-container-div-left-header {
    font-size: 3vh;
    margin-top: 1vh;
  }

  .convert-query-child-container-div-right-header {
    font-size: 3vh;
    margin-top: 1vh;
  }

  .convert-query-left-container-convert-btn {
    margin-top: 2vh;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2.7vh;
    font-size: 2.5vw;
  }

  #convert-query-input-query-dropdown {
    width: 10vh;
  }

  #convert-query-output-query-dropdown {
    width: 10vh;
  }
  .convert-query-child-container-text-area {
    font-size: 1.4vh;
    margin-right: -10vh;
    padding-right: 10vh;
  }
  .convert-query-child-container-format-btn {
    border: 0;
    font-size: 2.2vh;
    padding: 2px 5px;
  }
  .convert-query-left-container-convert-btn:hover .convert-query-left-container-convert-btn-icon {
    transform: translateX(0%);
  }
  #convert-query-icons-tooltip {
    padding: 3px;
    border-radius: 0px;
    font-size: 1.5vh;
  }

  .convert-query-child-container-dropdown-items {
    font-size: 3vw;
  }

  .convert-query-child-container-text-area::-webkit-scrollbar-track-piece:end {
    margin-bottom: 9vw;
  }

  .convert-query-child-container-text-area::-webkit-scrollbar-track-piece:start {
    margin-top: 9vw;
  }

  .convert-query-child-container-clear-icon {
    padding-right: 0.8vw;
    padding-bottom: 0.2vh;
  }

  .convert-query-child-container-dropdown{
    right: 0.5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .convert-query-main-container::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
      from 90deg at 0% 50%,
      #000000 0deg,
      rgba(255, 255, 255, 0) 360deg
    );
    transform: scaleY(-1);
    z-index: -1;
  }
  .convert-query-main-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .convert-query-child-container {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .convert-query-left-container-convert-btn {
    height: 9.08%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2.7vh;
  }

  .convert-query-child-container-text-area {
    font-size: 1.4vh;
    margin-right: -12vh;
    padding-right: 12vh;
  }

  .convert-query-child-container-text-area::-webkit-scrollbar-track-piece:end {
    margin-bottom: 7vw;
  }

  .convert-query-child-container-text-area::-webkit-scrollbar-track-piece:start {
    margin-top: 7vw;
  }

  .convert-query-child-container-div-left-header {
    margin-top: 1vh;
  }

  .convert-query-child-container-div-right-header {
    margin-top: 1vh;
  }

  .convert-query-child-container-clear-icon {
    padding-right: 0.8vw;
    padding-bottom: 0.2vh;
  }

  .convert-query-child-container-dropdown{
    right: 0.5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .convert-query-child-container-text-area {
    margin-right: -7vw;
    padding-right: 7vw;
  }

  .convert-query-child-container-text-area {
    font-size: 1.4vw;
    margin-right: -12vh;
    padding-right: 12vh;
  }

  #convert-query-input-query-dropdown {
    background-color: transparent;
    border: 0.5px groove #cccccc;
    width: 10vw;
    padding: 0.4vh 0.6vh;
    margin-right: 0.5vh;
    font-size: 1.3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
  }


#convert-query-output-query-dropdown {
  background-color: transparent;
  border: 0.5px groove black;
  color: #000;
  width: 10vw;
  padding: 0.4vh 0.6vh;
  margin-right: 0.5vh;
  font-size: 1.3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}

.convert-query-child-container-dropdown-items {
  font-size: 1.3vw;
}

.convert-query-left-container-convert-btn {
  width: 11vw;
  font-size: 1.7vw;
  margin-left: auto;
  justify-content: space-around;
  text-align: center;
  border-radius: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1%;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  transition:
    background-color,
    width 0.3s;
}


.convert-query-child-container-icon-btns {
  border: 0;
  font-size: 2vw;
  color: white;
}

.convert-query-child-right-container-icon-btns{
  border: 0;
  font-size: 2vw;
  color: black;
}
}
