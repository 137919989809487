.login-connect-with-google {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0%;
    z-index: 999999;
    position:absolute;
    right: 15px;
    border: none;
    border-radius: 0%;
    font-size: 0.89vw;
    padding: 0.83vw;
    padding-top: 0.72vw;
    padding-bottom: 0.72vw;
  }

  .login-google-icon {
    padding-right: 0.4vw;
    font-size: 1.34vw !important;
  }

  .logout-connect-with-google {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0%;
    z-index: 9999;
    position:absolute;
    right: 15px;
    border: none;
    border-radius: 0%;
    font-size: 0.89vw;
    padding: 0.83vw;
    padding-top: 0.72vw;
    padding-bottom: 0.72vw;
  }

  .logout-google-icon {
    padding-right: 0.4vw;
    font-size: 1.34vw !important;
  }

@media screen and (max-width: 767px) and (orientation: portrait) {
  .login-btn{
    display: none;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .login-btn{
    display: none;
  }
}