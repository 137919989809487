.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(1);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlayBlack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(1);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home{
    background-image: conic-gradient(
      from 244.3deg at 80% -10%,
      rgba(180, 180, 180, 100) 90deg,
      rgba(0, 0, 0, 100) 359deg
    );
  }

  .savedQueries{
    transform: scaleY(1);
    background-image: conic-gradient(
      from 280deg at 100% 0%,
      rgba(180, 180, 180, 100) 90deg,
      rgba(0, 0, 0, 100) 360deg
    );
  }
  
  .overlayBlack::after {
    content: '';
    border: 4px solid black;
    border-top: 4px solid transparent; 
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  .overlay::after {
    content: '';
    border: 4px solid #ccc;
    /* border: 4px solid white; */
    border-top: 4px solid transparent; 
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  /* .loadingBlack::after{
    border: 4px solid black;

  } */
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  