:root {
  --saved-queries-bg-color: conic-gradient(
    from 280deg at 100% 0%,
    rgba(180, 180, 180, 100) 90deg,
    rgba(0, 0, 0, 100) 360deg
  );
  --saved-queries-side-bar-color: #cccccc;
  --saved-queries-side-bar-border-color: #333333;
  --saved-queries-no-folder-icon: #ccc;
  --saved-queries-side-bar-scroll-bar-color:white;
  --saved-queries-side-bar-scroll-bar-thumb-color:white;
  --saved-queries-side-bar-scroll-bar-track-color: transparent;
  --saved-queries-content-color: white;
  --saved-queries-textarea-color:white;
  --saved-queries-textarea-border-color:white;
  --saved-queries-textarea-bg-color:#222;
  --saved-queries-textarea-scroll-bar-color:white;
  --saved-queries-textarea-scroll-bar-thumb-color:white;
  --saved-queries-textarea-scroll-bar-track-color:transparent;
  --saved-queries-querySelector-selection-bg-color: white;
  --saved-queries-querySelector-selection-color: black;
  --saved-queries-popup-content-bg-color: #f8f9fa;
  --saved-queries-popup-content-color: black;
  --saved-queries-popup-content-selection-bg-color: black;
  --saved-queries-popup-content-selection-color: white;
  --saved-queries-popup-input-border-color: #ccc;
  --saved-queries-hr-border-color: #ccc;
  --saved-queries-dropdown-right-click-bg-color: #f8f9fa;
  --saved-queries-dropdown-right-click-box-shadow-color:rgba(0, 0, 0, 0.2);
  --saved-queries-add-btn-bg-color:white;
  --saved-queries-add-btn-text-color:black;
  --saved-queries-add-btn-border-color:white;
  --saved-queries-add-btn-color:white;
  --saved-queries-save-btn-bg-color:white;
  --saved-queries-save-btn-border-color:white;
  --saved-queries-create-folder-btn-bg-color: #f5f5f5;
  --saved-queries-create-folder-btn-border-color: white;
  --saved-queries-create-folder-btn-color:black;
  --saved-queries-create-folder-btn-hover-border-color:white;
  --saved-queries-create-folder-btn-hover-bg-color:rgba(0,0,0,0.3);
  --saved-queries-create-folder-btn-hover-color:white;
  --saved-queries-btn-add-folder-bg-color:#f5f5f5;
  --saved-queries-btn-add-folder-color:black;
  --saved-queries-add-btn-text-color:black;
  --saved-queries-folder-btn-text-color-active:black;
  --saved-queries-add-btn-text-hover-color:#222;
  --saved-queries-add-btn-selected-color:#f5f5f5;
}

.saved-queries-bg-color::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--saved-queries-bg-color);
  z-index: -1;
}

.saved-queries-nav-bar {
  position: fixed;
  width: 100%;
  top: 0%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2.3vh;
  padding-bottom: 2vh;
  padding-right: 1.4vw;
}

.saved-queries-side-bar {
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  min-width: 22vw;
  background-color: transparent;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 18px;
  padding-top: 120px;
  font-size: 20px;
  color: var(--saved-queries-side-bar-color);
  border-right: 0.5px solid var(--saved-queries-side-bar-border-color);
}

.saved-queries-no-folders-container{
  position: fixed;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 
}

.saved-queries-no-folders-icon{
  font-size:15vw;
  padding-left: 4vw;
  color: var(--saved-queries-no-folder-icon);
}

.saved-queries-no-folders-child-container{
  /* border: 0.3px solid rgba(100,100,100,0.4); */
  margin-left: 5vw;
  width: 50vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  color: var(--saved-queries-no-folder-icon);
  gap: 1.6vh;
}

.saved-queries-no-folders-heading{
  font-size: 2vw;
  font-style: italic;
}
.saved-queries-no-folders-quote{
  font-size: 1vw;
}

.saved-queries-side-bar-list {
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent var(--saved-queries-side-bar-scroll-bar-color);
}

.saved-queries-side-bar-list::-webkit-scrollbar {
  width: 6px;
}

.saved-queries-side-bar-list::-webkit-scrollbar-thumb {
  background-color: var(--saved-queries-side-bar-scroll-bar-thumb-color);
  cursor: pointer!important;
}

.saved-queries-side-bar-list::-webkit-scrollbar-track {
  background-color: var(--saved-queries-side-bar-scroll-bar-track-color);
}

.saved-queries-btn-add-folder {
  width: 90%;
  height: 4vh;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4vh;
  margin-top: 10%;
  font-size: 15px;
  background-color: var(--saved-queries-btn-add-folder-bg-color);
  color:var(--saved-queries-btn-add-folder-color);
  border:none;
}

.saved-queries-folder {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: var(--saved-queries-add-btn-color);
  font-size: 15px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.saved-queries-folder:hover {
  background-color: var(--saved-queries-add-btn-text-hover-color);
}

.saved-queries-folder-selected-btn:hover {
  background-color: var(--saved-queries-add-btn-color);
}

.saved-queries-folder:active {
  background-color: var(--saved-queries-add-btn-color);
  color: var(--saved-queries-folder-btn-text-color-active);
}

.saved-queries-folder-selected-btn {
  background-color: var(--saved-queries-add-btn-selected-color);
  color: var(--saved-queries-folder-btn-text-color-active);
}

.plus-icon {
  margin-left: auto;
  font-size: 20px !important;
}

.arrow-icon-sidebar {
  margin-left: auto;
}

.saved-queries-main-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
}

.saved-queries-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  min-height: 90vh;
  color: var(--saved-queries-content-color);
  margin-left: 25%;
  padding-right: 11%;
  padding-top: 11vh;
}

.saved-queries-heading {
  font-weight: 400;
  font-size: 3.5vh;
  align-items: flex-start;
}

.saved-queries-side-nav-heading {
  font-weight: 400;
  font-size: 5vh;
  position: absolute;
  top: 10vh;
}
.saved-queries-btns {
  display: flex;
  flex-direction: row;
  width: 50vw;
  justify-content: flex-end;
  gap: 20px;
}

.saved-queries-textarea {
  margin-top: 2%;
  margin-bottom: 2%;
  border: 1px solid var(--saved-queries-textarea-border-color);
  background-color: var(--saved-queries-textarea-bg-color);
  width: 50vw;
  outline: none;
  resize: none;
  padding: 25px;
  color: var(--saved-queries-textarea-color);
  box-sizing: border-box;
  text-align: left;
  font-size: 1.6vh;
  font-family: "Courier New", monospace;
  overflow-y: scroll;
  max-height: 50vh;
}

.saved-queries-textarea::-webkit-scrollbar {
  width: 6px;
  background-color: var(--saved-queries-textarea-scroll-bar-color)!important;
  cursor: pointer;
}

.saved-queries-textarea::-webkit-scrollbar-thumb {
  background-color: var(--saved-queries-textarea-scroll-bar-thumb-color);
  
}

.saved-queries-textarea::-webkit-scrollbar-thumb:active {
  cursor: pointer!important;
}

.saved-queries-textarea::-webkit-scrollbar-track {
  background-color: var(--saved-queries-textarea-scroll-bar-track-color);
}

.saved-queries-saveBtn {
  width: 9%;
  font-size: 1.7vh;
  justify-content: center;
  border-radius: 0;
  display: flex;
  align-items: center;
  background-color: var(--saved-queries-save-btn-bg-color);
  border:1px solid var(--saved-queries-save-btn-border-color);
  cursor: pointer;
}

.saved-queries-saveBtspan {
  transform: translateY(4%);
}

.querySelector *::selection {
  background-color: var(--saved-queries-querySelector-selection-bg-color);
  color: var(--saved-queries-querySelector-selection-color);
}

.saved-queries-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(3px);
}

.saved-queries-popup-content {
  background-color: var(--saved-queries-popup-content-bg-color);
  position: relative;
  padding: 20px;
  border-radius: 0px;
  color: var(--saved-queries-popup-content-color);;
  width: 23%;
  font-size: 1.4vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-align: left;
  border:2px solid #ccc;
}

.saved-queries-popup-heading {
  font-size: 1.1vw;
}

.saved-queries-popup-input {
  border: 1px solid var(--saved-queries-popup-input-border-color);
  padding: 8px;
  width: 100%;
}

.saved-queries-faXMark {
  padding-top: 6px;
  font-size: 2vh;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.saved-queries-hr {
  border-color: var(--saved-queries-hr-border-color);
}

.saved-queries-save-btn {
  margin-top: 2%;
  align-self: flex-end;
  border-radius: 0%;
  font-size: 0.9vw;
}

.saved-queries-popup-content *::selection {
  background-color: var(--saved-queries-popup-content-selection-bg-color);
  color: var(--saved-queries-querySelector-selection-color);
}

/* starts here */

.saved-queries-dropdown-right-click ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.saved-queries-dropdown-right-click li {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  width: 120px;
}

.saved-queries-dropdown-right-click li:hover {
  background-color: var(--saved-queries-dropdown-right-click-bg-color);
}

.saved-queries-dropdown-right-click {
  text-align: left;
  position: absolute;
  background-color: #f8f9fa;
  font-size: 0.8vw;
  color: #000000;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px var(--saved-queries-dropdown-right-click-box-shadow-color);
  z-index: 1000;
}

.saved-queries-add-btn {
color:var(--saved-queries-add-btn-text-color);;
border: none;
background-color: var(--saved-queries-add-btn-bg-color);
width: 40px;
height: 40px;
border-radius: 2px;
}

button:hover{
  opacity: 0.9;
}

.saved-queries-create-folder-btn{
  margin-top: 1vh;
  background-color: var(--saved-queries-create-folder-btn-bg-color);
  width: fit-content;
  padding-left: 1.4vw;
  padding-right: 1.4vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 0px;
  font-size: 95%;
   border: 1px solid var(--saved-queries-create-folder-btn-border-color);
  color: var(--saved-queries-create-folder-btn-color);
  cursor: pointer;
}
.saved-queries-create-folder-btn:hover{
  border:1px solid var(--saved-queries-create-folder-btn-hover-border-color);
  background-color: var(--saved-queries-create-folder-btn-hover-bg-color);
  color: var(--saved-queries-create-folder-btn-hover-color);
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .saved-queries-textarea {
    width: 100%;
    margin-top: 10%;
    height: 20vh;
    margin-bottom: 10%;
    font-size: 1.4vh;
  }

  .saved-queries-saveBtspan {
    width: 40%;
    margin-right: 0;
  }

  .saved-queries-content {
    padding: 0;
    width: 85%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .saved-queries-textarea {
    width: 100%;
    margin-top: 10%;
    height: 20vh;
    margin-bottom: 10%;
    font-size: 1.4vh;
  }

  .saved-queries-saveBtspan {
    width: 40%;
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
