.explain-query-bodyBgColor::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: conic-gradient(
    from 180deg at 50% 0%,
    rgba(255, 255, 255, 0) 0deg,
    #000000 358deg
  );
  z-index: -1;
}

.sql-li {
  list-style-type: none;
  padding-left: 20px;
  position: relative;
  margin-bottom: 8px;
  line-height: 1.5;
  text-indent: -16px;
}

.sql-li::before {
  content: "□";
  font-size: 16px;
  color: var(--sql-li-color);
  margin-right: 8px;
}

.btn-explain-query-home {
  margin-right: 1.2vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  border-radius: 0%;
  font-size: 0.89vw;
  padding: 0.55vw;
  padding-left: 1.11vw;
  padding-right: 1.11vw;
}

.explain-query-mainContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.explain-query-child-container-clear-icon {
  position: absolute;
  bottom: 2%;
  right: 2%;
  z-index: 2;
  padding-right: 0.6vw;
  padding-bottom: 0.6vh;
}

.explain-query-nav-bar {
  position: absolute;
  width: 100%;
  top: 0%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2.3vh;
  padding-bottom: 2vh;
  padding-right: 1.4vw;
}

.explain-query-home-icon {
  padding-left: 0.6vw;
}

.home-icon {
  font-size: 0.78vw !important;
}
.explain-query-btn-chatbot-home {
  margin-right: 2vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  border-radius: 0%;
  font-size: 0.89vw;
  padding: 1.39vw;
  padding-top: 1.31vh;
  padding-bottom: 1.31vh;
}

.explain-query-querySelectorLeft *::selection {
  background-color: black;
  color: white;
}

.explain-query-querySelectorOutput *::selection {
  color: black;
  background-color: white;
}

.explain-query-querySelectorOutput {
  color: white !important;
}

.explain-query-childContainer {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 7vh;
}

.explain-query-containerContent {
  width: 73.72%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.explain-query-containerContent-right {
  width: 73.72%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.explain-query-header {
  margin-top: 10.11%;
  margin-bottom: 2.32%;
  font-weight: 400;
  font-size: 5.5vh;
  line-height: 6.89vh;
  color: black;
}

.explain-query-header-right {
  margin-top: 10.11%;
  margin-bottom: 2.32%;
  font-weight: 400;
  font-size: 5.5vh;
  line-height: 6.89vh;
  color: black;
}

.explain-query-fontWhite {
  color: white;
}
.explain-query-textBox {
  border-radius: 2px;
  width: 100%;
  height: 63.74%;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.explain-query-textBox-right {
  border-radius: 2px;
  width: 100%;
  height: 63.74%;
  padding-bottom: 0;
  margin-bottom: 0.5vh;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.explain-query-textArea {
  width: 92%;
  height: 98%;
  background: transparent;
  border: 0;
  outline: none;
  resize: none;
  padding-top: 8%;
  margin-bottom: 8%;
  font-size: 1.8vh;
  margin-right: -5.6vw;
  padding-right: 5.6vw;
  position: absolute;
  z-index: 1;
  left: 7%;
}

.explain-query-textAreaRight {
  width: 92%;
  height: 98%;
  background: transparent;
  border: 0;
  outline: none;
  resize: none;
  padding-top: 8%;
  margin-bottom: 8%;
  font-size: 1.8vh;
  margin-right: -1.6vw;
  padding-right: 1.6vw;
  position: absolute;
  z-index: 1;
  left: 7%;
}

.explain-query-leftTextBox {
  border: 1px solid #000000;
  position: relative;
  padding-right: 0;
  padding-top: 1%;
}

.explain-query-rightTextBox {
  border: 1px solid #cccccc;
  position: relative;
  padding-right: 0;
  padding-top: 1%;
  color: white;
}
.explain-query-animateBorder {
  animation: borderChange 3s infinite;
}
@keyframes borderChange {
  0% {
    border-left-color: white;
    border-left-width: 3px;
  }
  25% {
    border-top-color: white;
    border-top-width: 3px;
  }
  50% {
    border-right-color: white;
    border-right-width: 3px;
  }
  75% {
    border-bottom-color: white;
    border-bottom-width: 3px;
  }
  90% {
    border-color: white;
    border-width: 5px;
    border-bottom-color: white;
  }
}

.explain-query-convertBtn {
  margin-top: 3.04%;
  width: 24%;
  font-size: 2vh;
  margin-left: auto;
  justify-content: space-around;
  text-align: center;
  border-radius: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1%;
  /* background-color: #000000; */
  color: white;
  border: none;
  cursor: pointer;
  /* transition: background-color, width 0.3s; */
}

@media (hover: hover) {
  /* .explain-query-convertBtn:hover {
    background-color: #000000;
    color: white;
    width: 30%;
  }
  .explain-query-convertBtn:hover .explain-query-convertText {
    opacity: 0;
  }
  .explain-query-convertBtn:hover .explain-query-convertIcon {
    transform: translateX(-250%);
    transition: transform, font-size 0.3s;
    font-size: 3vh;
  }
  .explain-query-iconSize:hover {
    font-size: 125%;
    opacity: 1;
  } */
}


.explain-query-convertText {
  margin-right: 0px;
  transition: opacity 0.3s;
}

.explain-query-convertIcon {
  transition: transform 0.3s;
}
.explain-query-convertBtn:active {
  transform: translateY(10%);
}

.explain-query-utilBtn {
  border: 0;
  font-size: 2.2vh;
}

.explain-query-outputText {
  white-space: pre-line;
  text-align: left;
}

.explain-query-iconSize {
  transition: font-size 0.3s;
  opacity: 0.9;
}

.explain-query-dd {
  height: 0%;
  position: absolute;
  top: 2%;
  z-index: 2;
}
.explain-query-cornerBtn {
  position: absolute;
  bottom: 0%;
  z-index: 2;
}

.explain-query-formatButton {
  position: absolute;
  bottom: 2%;
  left: 2%;
  z-index: 2;
  transform: scaleX(-1);
}
#inputQueryDropdownExplainQuery {
  background-color: transparent;
  border: 0.5px groove #222222;
  width: 14vh;
  padding: 0.4vw 0.6vw;
  margin-right: 0.5vw;
  font-size: 1.6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}

#inputQueryDropdownExplainQuery::after {
  display: none !important;
}

#outputQueryDropdownExplainQuery {
  background-color: transparent;
  border: 0.5px groove white;
  color: white;
  width: 14vh;
  padding: 0.4vw 0.6vw;
  margin-right: 0.5vw;
  font-size: 1.6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}

#outputQueryDropdownExplainQuery::after {
  display: none !important;
}

.explain-query-outputButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding-top: 1%;
}

#tooltipStyle {
  padding: 0.2vh 0.33vw;
  border-radius: 0px;
  font-size: 75%;
}

textarea::-webkit-scrollbar {
  width: 0.5em;
  display: block;
  margin-left: auto;
}

textarea::-webkit-scrollbar-track {
  background: transparent;
}

textarea::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

.explain-query-leftTextArea::-webkit-scrollbar-thumb {
  background-color: black;
}

.explain-query-rightTextArea::-webkit-scrollbar-thumb {
  background-color: white;
}

.explain-query-child-container-format-icon {
  position: absolute;
  bottom: 2%;
  left: 2%;
  z-index: 2;
  transform: scaleX(-1);
}

textarea::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 8vh;
}

textarea::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 8vh;
}

.explain-query-child-container-icon-btns {
  border: 0;
  font-size: 2.2vh;
  padding-right: 0.6vw;
  padding-bottom: 0.6vh;
}

.explain-query-child-container-dropdown {
  height: 0%;
  position: absolute;
  top: 2%;
  z-index: 2;
}

.explain-query-child-container-output-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding-top: 1%;
}
@media screen and (max-width: 767px) and (orientation: portrait) {
  .explain-query-bodyBgColor::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
      from 90deg at 0% 50%,
      #000000 0deg,
      rgba(255, 255, 255, 0) 360deg
    );
    z-index: -1;
  }
  .explain-query-child-container-format-btn {
    border: 0;
    font-size: 2.2vh;
    padding: 2px 5px;
  }

  .explain-query-mainContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .explain-query-childContainer {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .explain-query-convertBtn {
    margin-top: 7.04%;
    width: 35%;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2.7vh;
    font-size: 1.7vh;
  }
  .btn-explain-query-home {
    margin-right: 1.2vh;
    font-size: 1.2vh;
    padding: 0.55vh;
    padding-left: 1vh;
    padding-right: 1vh;
  }
  #inputQueryDropdownExplainQuery {
    width: 10vh;
  }

  #outputQueryDropdownExplainQuery {
    width: 10vh;
  }
  .explain-query-textArea {
    font-size: 1.4vh;
    margin-right: -10vh;
    padding-right: 10vh;
  }
  .explain-query-utilBtn {
    border: 0;
    font-size: 2.2vh;
    padding: 0.2vh 0.27vw;
  }
  #tooltipStyle {
    padding: 0.16vw;
    border-radius: 0px;
    font-size: 1.5vh;
  }
  .explain-query-header {
    margin-top: 1vh;
    font-size: 3vh;
  }
  .explain-query-header-right {
    margin-top: 1vh;
    font-size: 3vh;
  }
  .home-icon {
    padding-left: 0.6vh;
    font-size: 0.9vh !important;
  }
  .explain-query-ddItemStyle {
    font-size: 1.5vh;
  }
  .explain-query-child-container-dropdown {
    right: 0.5%;
  }
  .explain-query-child-container-icon-btns {
    padding-right: 0.8vw;
    padding-bottom: 0vh;
  }
  textarea::-webkit-scrollbar-track-piece:end {
    margin-bottom: 9vw;
  }

  textarea::-webkit-scrollbar-track-piece:start {
    margin-top: 9vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .explain-query-bodyBgColor::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
      from 90deg at 0% 50%,
      #000000 0deg,
      rgba(255, 255, 255, 0) 360deg
    );
    z-index: -1;
  }
  .explain-query-mainContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .explain-query-childContainer {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .explain-query-convertBtn {
    height: 9.08%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2.7vh;
  }

  .explain-query-textArea {
    font-size: 1.4vh;
    margin-right: -12vh;
    padding-right: 12vh;
  }
  .explain-query-header {
    margin-top: 1vh;
  }
  .btn-explain-query-home {
    margin-right: 1.2vh;
    font-size: 1vh;
    padding: 0.55vh;
    padding-left: 1vh;
    padding-right: 1vh;
  }
  .home-icon {
    padding-left: 0.6vh;
    font-size: 0.9vh !important;
  }

  textarea::-webkit-scrollbar-track-piece:end {
    margin-bottom: 7vw;
  }

  textarea::-webkit-scrollbar-track-piece:start {
    margin-top: 7vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .explain-query-textArea {
    margin-right: -7vw;
    padding-right: 7vw;
    font-size: 1.4vw;
  }

  .explain-query-convertBtn {
    font-size: 1.6vw;
    width: 30%;
  }

  #inputQueryDropdownExplainQuery {
    font-size: 1.5vw;
  }

}
