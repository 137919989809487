.hover-effect:hover {
  height: 120px;
}

.hover-effect {
  transition: height 0.3s ease;
}

.nav-bar-logo {
  position: absolute;
  top: 0px;
  left: 3px;
  font-size: 40px;
  height: 110px;
}

.nav-bar {
  position: fixed;
  z-index: 99999;
  width: 100%;
  top: 0%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2.3vh;
  padding-bottom: 2vh;
  padding-right: 1.4vw;
}

.nav-bar-home-btn {
  position: relative;
  background-color: transparent;
  top: 0%;
  padding-left: 1.4vw;
  padding-right: 1.4vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 0px;
  font-size: 95%;
  /* font-weight: 600; */
}

.nav-bar-home-btn.white {
  border: 1px solid white;
  color: white;
}

.nav-bar-home-btn.black {
  border: 1px solid black;
  color: black;
}

.nav-bar-home-btn:hover {
  font-size: 100%;
  transition: font-size 0.3s ease;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .nav-bar-logo {
    height: 60px;
  }

  .nav-bar {
    padding-top: 1vh;
    position: fixed;
    z-index: 500;
  }

  .nav-bar.dark {
    background-image: conic-gradient(
      from 280deg at 100% 0%,
      rgba(180, 180, 180, 100) 90deg,
      rgba(0, 0, 0, 100) 360deg
    );
  }

  .nav-bar.light {
    background-color: white;
  }

  .nav-bar-home-btn {
    margin-top: 0.8vh;
    font-size: 3.2vw;
    right: 15%;
  }

  .nav-bar-home-btn.white {
    border: 1px solid white;
    color: white;
  }

  .nav-bar-home-btn.black {
    border: 1px solid black;
    color: black;
  }

  .hover-effect {
    height: 8vh;
    transition: none;
  }
  
  .hover-effect:hover {
    height: 8vh;
  }
}


@media screen and (max-width: 1024px) and (orientation: landscape) {
  .hover-effect {
    height: 8vw;
    transition: none;
  }
  
  .hover-effect:hover {
    height: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .nav-bar-home-btn {
    right: 22%;
  }
}