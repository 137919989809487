.dispatch-message {
  position: relative;
  color: black;
  padding: 10px;
  text-align: center;
  position: fixed;
  top: 1vh;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid transparent;
  max-width: 40%;
  font-size: 0.9vw;
  z-index: 999;
  padding-left: 10px;
  padding-right: 40px;
  text-align: left;
  transition: opacity 0.6s, transform 0.6s;
}

.dispatch-message.visible {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.dispatch-message.hidden {
  transform: translateX(-50%) translateY(-100%);
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.dispatch-message.error {
  /* background-color: rgba(255, 252, 251, 0.9); */
  border-color: #f35c3e;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 252, 251);
}

.dispatch-message.success {
  color: black;
  border-color: #d6e9c6;
  background-color: rgba(255, 255, 255, 0.8);
}

.dispatchMessage-queries-faXMark {
  /* padding-top: 6px; */
  font-size: 2vh;
  background-color: transparent;
  border: none;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 5px;
  cursor: pointer;
}

.dispatchMessage-queries-faXMark.error {
  color: white;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .dispatch-message {
    font-size: 2vw;
    width: 100vw;
    max-width: 80%;
    z-index: 10000;
    z-index: 99999999;
  }

  .dispatchMessage-queries-faXMark {
    font-size: 2vw;
  }
}
