.home-bg-color::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: conic-gradient(
    from 244.3deg at 80% -10%,
    rgba(180, 180, 180, 100) 90deg,
    rgba(0, 0, 0, 100) 359deg
  );
  transform: scaleY(-1);
  z-index: -1;
}

.home-nav-bar-logo {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 3px;
  font-size: 40px;
  /* font-weight: 800; */
  height: 110px;
  /* padding-top: 2.3vh; */
  /* padding-left: 0.3vw;  */
  /* color: red; */
}

.online-icon-home {
  width: 0.55vw;
  height: 0.55vw;
  border-radius: 75%;
  padding: 0.27vw;
  background-color: #6ccc15;
}

.offline-icon-home {
  width: 0.55vw;
  height: 0.55vw;
  border-radius: 75%;
  padding: 0.27vw;
  background-color: #ff0000;
}

.home-main-container {
  display: flex;
  justify-content: center;
  padding: 2vh;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

::selection {
  background-color: white;
  color: black;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  color: white;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-heading {
  font-weight: 400;
  font-size: 2.02vw;
  align-self: flex-start;
  padding-left: 3vw;
  margin-bottom: 1%;
  font-style: italic;
  font-weight: 300;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.home-btn {
  margin-top: 4%;
  margin-bottom: 1%;
  width: 90%;
  padding: 1vw;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  font-size: 1.3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}

.home-btn:active {
  transform: translateY(5%);
}

.home-nav-bar {
  position: absolute;
  width: 100%;
  top: 0%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2.3vh;
  padding-bottom: 2vh;
  padding-right: 1.4vw;
}

.home-connect-with-google {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0%;
  z-index: 999999;
  position: absolute;
  right: 15px;
  border: none;
  border-radius: 0%;
  font-size: 0.89vw;
  padding: 0.83vw;
  padding-top: 0.72vw;
  padding-bottom: 0.72vw;
}

.home-google-icon {
  padding-right: 0.4vw;
  font-size: 1.34vw !important;
}

.disabledButton {
  opacity: 0.7;
  border: none;
  cursor: not-allowed !important;
}

.disabledButton:hover {
  opacity: 0.4;
}

#homeTooltip {
  background-color: transparent;
  padding: 0.6vh 1.11vw;
  border-radius: 0px;
  font-size: 75%;
  border: 1px solid #cccccc;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .home-bg-color::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: conic-gradient(
      from 214.3deg at 100% -10%,
      rgba(180, 180, 180, 100) 90deg,
      rgba(0, 0, 0, 100) 359deg
    );
    transform: scaleY(-1);
    z-index: -1;
  }
  .home-main-container {
    padding: 1.4vw;
    height: 100vh;
  }

  .home-connect-with-google {
    font-size: 0.89vh;
    padding: 0.83vh;
    padding-top: 0.72vh;
    padding-bottom: 0.72vh;
  }

  .home-google-icon {
    font-size: 1.34vh !important;
    padding-right: 0.4vh;
  }

  .online-icon-home,
  .offline-icon-home {
    width: 0.55vh;
    height: 0.55vh;
    padding: 0.27vh;
  }

  .home-content {
    margin-top: -6vh;
    width: 85%;
  }

  .home-heading {
    margin-right: auto;
    font-size: 3.52vh;
    margin-bottom: 2vh;
  }

  .home-btn {
    padding: 1vh;
    padding-left: 3.67vw;
    padding-right: 3.39vw;
    font-size: 1.8vh;
    width: 80vw;
  }

  #homeTooltip {
    background-color: black;
    padding: 0.6vh 1.11vw;
    border-radius: 0px;
    font-size: 75%;
    border: 1px solid #cccccc;
    position: absolute;
    z-index: 1000;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .home-main-container {
    min-height: 100vh;
    overflow-y: scroll;
  }
  

  .home-content {
    margin-top: 0vw;
    width: 65%;
  }

  .home-heading {
    font-size: 2.52vw;
    margin-bottom: 2vh;
  }

  .home-btn {
    margin-top: 4%;
    margin-bottom: 1%;
    width: 90%;
    padding: 1vw;
    padding-left: 1.67vw;
    padding-right: 1.39vw;
    background-color: #f5f5f5;
    border: none;
    cursor: pointer;
    font-size: 1.8vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
  }
}
