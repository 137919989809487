.documentation-bg-color::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: conic-gradient(
    from 244.3deg at 80% -10%,
    rgba(180, 180, 180, 100) 90deg,
    rgba(0, 0, 0, 100) 359deg
  );
  transform: scaleY(-1);
  z-index: -1;
}

.documentation-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  
}

.documentation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.documentation-heading {
  font-weight: 400;
  font-size: 1.82vw;
  align-self: flex-start;
  margin-left: 3vw;
  margin-bottom: 1%;
  font-style: italic;
  font-weight: 300;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.documentation-btn {
  margin-top: 4%;
  margin-bottom: 1%;
  width: 90%;
  padding: 1vw;
  padding-left: 1.67vw;
  padding-right: 1.39vw;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  font-size: 1.3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}

.documentation-btn:active {
  transform: translateY(5%);
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .documentation-bg-color::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: conic-gradient(
      from 214.3deg at 100% -10%,
      rgba(180, 180, 180, 100) 90deg,
      rgba(0, 0, 0, 100) 359deg
    );
    transform: scaleY(-1);
    z-index: -1;
  }

  .documentation-btn {
    padding: 1vh;
    padding-left: 3.67vw;
    padding-right: 3.39vw;
    font-size: 1.8vh;
    width: 80vw;
    
  }

  .documentation-heading {
    font-size: 2.5vh;
    margin-bottom: 2vh;
  }

  .documentation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    color: white;
    margin-top: -6vh;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {

  .documentation-content {
    margin-top: -6vh;
  }

  .documentation-heading {
    font-size: 2.52vw;
    margin-bottom: 2vh;
  }

  .documentation-btn {
    margin-top: 4%;
    width: 90%;
    padding: 1vw;
    padding-left: 1.67vw;
    padding-right: 1.39vw;
    background-color: #f5f5f5;
    border: none;
    cursor: pointer;
    font-size: 1.8vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
  }
  
}